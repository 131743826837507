* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*ion-router-outlet {*/
/*    overflow: auto;*/
/*}*/

body {
    background: #1ba552;
}
ion-router-outlet {
    background: #1ba552 url('/assets/images/bg.png') no-repeat center center / cover;;
}
ion-content {
    --background: #0000;
}
ion-content.parent {
    --background: #3368;
}

.ios .wrapper {
    margin-top: 20px;
}

*:before, *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*::-webkit-input-placeholder {
    color: #666;
    opacity: 1;
}

*:-moz-placeholder {
    color: #666;
    opacity: 1;
}

*::-moz-placeholder {
    color: #666;
    opacity: 1;
}

*:-ms-input-placeholder {
    color: #666;
    opacity: 1;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
    color: #666;
}

body input:required:valid,
body textarea:required:valid {
    color: #666;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body, .body {
    font-size: 16px;
    min-width: 320px;
    position: relative;
    line-height: normal;
    font-family: "Roboto", sans-serif;
    background: url("/assets/images/bg.png") no-repeat center;
    background-size: cover;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

.text-center {
    text-align: center;
}

a {
    display: block;
    text-decoration: none;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    outline: none;
}

a:hover, a:focus {
    text-decoration: none;
}

.wrapper {
    /*overflow-x: hidden;*/
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.content {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.content .container {
    min-height: 100vh;
}

.footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 16px;
}

.logo {
    margin: auto;
    max-width: 338px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.header .container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header .list-stars {
    margin: 0 auto;
}

.header .list-stars li {
    margin: 0 17px 0 0;
    width: 24px;
    height: 24px;
}

.header .list-stars li img {
    width: 100%;
}

.header .list-stars li:last-child {
    margin: 0;
}

.letter {
    position: relative;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    -webkit-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    letter-spacing: 0.05em;
    color: #fff;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
}


.letter .tooltip-box::before {
    display: none;
}
.letter .tooltip-box::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5.5px solid transparent;
    border-right: 5.5px solid transparent;
    border-bottom: 9px solid #fff;
    left: 50%;
    transform: translate(-50%, 0);
    top: -9px;
    position: absolute;
}
.letter .tooltip-wrapper {
    bottom: 0;
    z-index: 2;
}
.letter .tooltip-box {
    bottom: -55px;
}
.letter:hover .tooltip-box {
    display: block;
}

.letter-num {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.letter-icon {
    width: 12px;
    height: 12px;
    position: absolute;
    top: -4px;
    right: -3px;
}

.card {
    width: 112px;
    height: 159px;
    border-radius: 8px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: #fff;
    overflow: hidden;
    margin: 0 auto 0;
    position: relative;
}

.card-img {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 104px;
    margin: 0 auto 7px;
    position: relative;
    overflow: hidden;
}

.card-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.card-name {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #4F4F4F;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.card-characteristics {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #DBDBDB;
}

.card-characteristics__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 8px 0 0;
}

.card-characteristics__item:last-child {
    margin: 0;
}

.card-characteristics__text {
    margin: 0 0 0 4px;
}

.card-r5 {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
}

.card-r-5 {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

.z-index-2 {
    z-index: 2;
}

.z-index-1 {
    z-index: 1;
}

.mt-37 {
    margin-top: 37px;
}

.mt-80 {
    margin-top: 80px;
}

.icon-stars {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: rgba(79, 79, 79, 0.8);
    border-radius: 50%;
    padding: 7px;
}

.card-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-self: flex-start;
    margin-top: 10vh;
}

.col-3 {
    width: 33.3%;
}

.level-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: center;
    height: 109px;
    position: relative;
    width: 33.3%;
}

.level-box .list-stars {
    margin: -15px auto 0;
}

.level-box-next .level-score {
    background: #1FD32E;
}

.list-stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.list-stars li {
    width: 14px;
    height: 14px;
}

.list-stars li a {
    width: 100%;
    height: 100%;
}

.list-stars li a img {
    width: 100%;
}

.level-box__number {
    position: relative;
    min-height: 109px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.level-box__number img {
    /*height: 100%;*/
}

.level-box__number-2-digits .level-digit:nth-child(2n) {
    margin-left: 4px;
}
.level-box__number-2-digits .level-digit-0 {
    width: calc(69px * 0.7);
}
.level-box__number-2-digits .level-digit-1 {
    width: calc(46px * 0.7);
}
.level-box__number-2-digits img.level-digit-1 {
    width: calc(46px * 0.64);
}
.level-box__number-2-digits .level-digit-2 {
    width: calc(68px * 0.7);
}
.level-box__number-2-digits .level-digit-3 {
    width: calc(68px * 0.7);
}
.level-box__number-2-digits .level-digit-4 {
    width: calc(77px * 0.7);
}
.level-box__number-2-digits .level-digit-5 {
    width: calc(69px * 0.7);
}
.level-box__number-2-digits .level-digit-6 {
    width: calc(71px * 0.7);
}
.level-box__number-2-digits .level-digit-7 {
    width: calc(69px * 0.7);
}
.level-box__number-2-digits .level-digit-8 {
    width: calc(71px * 0.7);
}
.level-box__number-2-digits .level-digit-9 {
    width: calc(71px * 0.7)
}

.level-box__number-3-digits {
    transform: rotate(35deg);
}
.level-box__number-3-digits + .level-score {
    transform: translate(-50%, 20%);
}

.level-box__number-3-digits .level-digit:nth-child(2),
.level-box__number-3-digits .level-digit:nth-child(3) {
    margin-left: 2px;
}
.level-box__number-3-digits .level-digit-0 {
    width: calc(69px * 0.5);
}
.level-box__number-3-digits .level-digit-1 {
    width: calc(46px * 0.5);
}
.level-box__number-3-digits img.level-digit-1 {
    width: calc(46px * 0.43);
}
.level-box__number-3-digits img.level-digit-2 {
    width: calc(68px * 0.5);
    margin-top: -4px;
}
.level-box__number-3-digits .level-digit-2 {
    width: calc(68px * 0.5);
}
.level-box__number-3-digits .level-digit-3 {
    width: calc(68px * 0.5);
}
.level-box__number-3-digits .level-digit-4 {
    width: calc(77px * 0.5);
}
.level-box__number-3-digits .level-digit-5 {
    width: calc(69px * 0.5);
}
.level-box__number-3-digits .level-digit-6 {
    width: calc(71px * 0.5);
}
.level-box__number-3-digits .level-digit-7 {
    width: calc(69px * 0.5);
}
.level-box__number-3-digits .level-digit-8 {
    width: calc(71px * 0.5);
}
.level-box__number-3-digits .level-digit-9 {
    width: calc(71px * 0.5)
}



.level-box__number-2-digits {
    /*width: 100%;*/
    /*margin-left: 20%;*/
}

/*.level-box__number__digit + .level-box__number__digit {*/
/*    margin-left: -40px;*/
/*}*/

/*.level-box__number__digit-1 {*/
/*    display: block;*/
/*    margin-left: -20px;*/
/*    margin-right: -20px;*/
/*}*/

.level-box__number__digit {
    position: relative;
}


.level-box__number__digit > img {
    width: 100%;
    height: 100%;
}

.level-box__number__digit {
}
.level-box__number__digit .level-box__number__digit__background-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
}

.lock-icon {
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-14.14%, #FFDF65), color-stop(123.06%, #FFFFFF));
    background: linear-gradient(180deg, #FFDF65 -14.14%, #FFFFFF 123.06%);
}

.tooltip-wrapper {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.level-box:hover .tooltip-wrapper .tooltip-box {
    display: block;
}

.level-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.tooltip-box {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    color: #4F4F4F;
    font-size: 14px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px;
    width: 130px;
    display: none;
    -webkit-transition: .3s ease;
    transition: .3s ease;
}

.tooltip-box:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5.5px solid transparent;
    border-right: 5.5px solid transparent;
    border-top: 9px solid #fff;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: -9px;
    position: absolute;
}

.level-score {
    padding: 2px 8px;
    font-size: 13px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    background: #1C9781;
    border-radius: 16px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.animation-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.animation-text {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto auto;
}

.confetti-animation-wrapper .animation-text {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
}
.confetti-animation-wrapper .animation-img {
    position: fixed;
    top: 0;
    height: 100vh;
}
.animation-text p {
    margin: 0 auto 35px;
}

.animation-text p:last-child {
    margin: 0;
}

.timer-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.timer-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.timer-box span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
    margin: 0 4px 0 0;
    color: #fff;
    letter-spacing: 0.05em;
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
}

.timer-box span:last-child {
    margin: 0;
}

.timer-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
}

.links-skip {
    color: #FFDB1D;
    font-size: 14px;
}

.video-box {
    position: relative;
    width: calc(100% + 40px);
    background: #C4C4C4;
    height: 203px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
    margin: 0 -20px 16px;
}

.video-box__youtube-player-hider {
    position: absolute;
    width: 100%;
    height: 100%;
}

.video-box p {
    text-align: center;
}

.btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.btn-accent {
    margin: 0 auto;
    width: 228px;
    height: 48px;
    padding: 0;
    border-radius: 48px;
    overflow: hidden;
}

button {
    outline: none;
    border: none;
    background: transparent;
}

input {
    width: 100%;
    -webkit-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    height: 48px;
    border-radius: 8px;
    outline: none;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    font-family: "Roboto", sans-serif;
    color: #4F4F4F;
    background-color: #ffffff;
    border: none;
    font-size: 20px;
    font-weight: bold;
}

input::-webkit-input-placeholder {
    color: #9B9B9B;
    font-size: 16px;
    font-weight: normal;
}

input::-moz-placeholder {
    color: #9B9B9B;
    font-size: 16px;
    font-weight: normal;
}

input::-ms-input-placeholder {
    color: #9B9B9B;
    font-size: 16px;
    font-weight: normal;
}

input::placeholder {
    color: #9B9B9B;
    font-size: 16px;
    font-weight: normal;
}

label {
    width: 100%;
    display: block;
    margin: 0 0 16px;
}

.animation-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
}

.img-figure {
    margin: -30px auto 0;
}

.card-product {
    position: relative;
    width: 256px;
    height: 50vh; /*392px;*/
    max-height: 392px;
    margin: 0 auto 22px;
}

.card-product:before {
    content: '';
    position: absolute;
    width: 246px;
    height: 95%; /*376px;*/
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background: #DBDBDB;
    -webkit-transform: rotate(-2.87deg);
    transform: rotate(-2.87deg);
    top: 10px;
    left: -20px;
}

.card-product:after {
    content: '';
    position: absolute;
    width: 246px;
    height: 95%; /*376px;*/
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background: #DBDBDB;
    -webkit-transform: matrix(-1, -0.05, -0.05, 1, 0, 0);
    transform: matrix(-1, -0.05, -0.05, 1, 0, 0);
    top: 10px;
    right: -20px;
}

.card-product__box {
    position: relative;
    z-index: 1;
    background: #fff;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0 16px;
}

.card-product__box-star {
    height: 60%;
}

.card-product__box-star .sequence-animation {
    position: relative;
    height: 100%;
}

.card-product__box-star .star-max-wrapper {
    position: relative;
    top: 50%;
}
.card-product__box-star .sequence-animation img {
    position: relative;
    top: -50%;
}


.card-product-img {
    width: 100%;
    height: 256px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto auto;
}

.card-product-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.card-product-word {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.card-product-word .row {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 8px;
}

.card-product-word .row:last-child {
    margin: 0;
}

.card-product-word__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    text-align: center;
    margin: 0 4px 0 0;
    width: 48px;
    height: 48px;
    letter-spacing: 0.05em;
    font-size: 26px;
    font-weight: bold;
    color: #4F4F4F;
    text-transform: uppercase;
}

.card-product-word__box:last-child {
    margin: 0;
}

.color-red {
    color: #FF3D43;
}

.variable-letter {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.container > .variable-letter {
    margin-bottom: 5vh;
}

.variable-letter .card-product-word__box {
    background: #FFFFFF;
    width: 64px;
    height: 64px;
    font-size: 38px;
}

.image-good {
    z-index: 2;
    width: 100vw;
    height: 64px;
    margin-bottom: 5vh;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-good .sequence-animation {
    position: fixed;
    bottom: 0;
    height: 25vh;
    width: auto;
}
.image-good .sequence-animation img {
    height: 100%;
}

.ready-word {
    width: 100%;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 44px auto;
}

.ready-word .card-product-word__box {
    background: #1FD32E;
    color: #fff;
}

.card-product__box-overlay {
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.card-product__box-overlay > * {
    position: relative;
    z-index: 1;
}

.card-product__box-overlay .card-product-word {
    margin: 16px auto;
}

.bg-white {
    background: #fff;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 3;
}

.hidden {
    opacity: 0;
    top: -100vh;
}
.overlay.hidden {
    opacity: 0;
}
.overlay {
    opacity: 1;
    display: block;
    transition: opacity 0.4s ease-in-out;
}
.modal.hidden {
    transform: translate(-50%, -500%);
}
.modal {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background: #FFFFFF;
    padding: 16px;
    max-width: 328px;
    width: 100%;
    transition: transform 0.2s linear;
    display: block;
}

.form,
form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.form button, .form .btn,
form button, form .btn {
    width: 163px;
    margin: 0 auto;
}

.form-modal2 button, .form-modal2 .btn {
    width: 175px;
}

.modal-title {
    text-align: center;
    color: #1C9C41;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 auto 16px;
}

.error {
    width: 100%;
    text-align: center;
    color: #FF3D43;
    margin: 0 auto 16px;
}

.btn-add {
    margin: auto;
}

.header-title {
    width: 100%;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.radio {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: #4F4F4F;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

.radio .radio-inp {
    display: none;
}

.radio .radio-custom {
    position: relative;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    border: 1.5px solid #1C9C41;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    margin: 0 28px 0 20px;
}

.radio .label {
    display: inline-block;
}

.radio .radio-inp:checked + .radio-custom {
    border: 6px solid #1C9C41;
}

.checkbox {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: #4F4F4F;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

.checkbox .checkbox-inp {
    display: none;
}

.checkbox .checkbox-custom {
    position: relative;
    min-width: 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    border: none;
    border-radius: 4px;
    background: #1C9C41;
    cursor: pointer;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    margin: 0 28px 0 20px;
}

.checkbox .label {
    display: inline-block;
}


.checkbox .checkbox-inp:checked + .checkbox-custom:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 6px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: url("/assets/images/check.svg") no-repeat center;
    background-size: contain;
}

.popup-box {
    width: 100%;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 16px;
    background: #fff;
    margin: 0 0 16px;
}

.popup-box:last-child {
    margin: 0;
}

.popup-box-title {
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin: 0 auto 16px;
    color: #1C9C41;
    font-size: 20px;
    font-weight: bold;
}

.form-password .btn {
    width: 175px;
}

.button-group {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.button-group .btn {
    width: 100%;
    margin: 0 0 16px;
}

.btn-border {
    color: #FFDB1D;
    font-size: 16px;
    font-weight: normal;
    height: 44px;
    width: 100%;
    border-radius: 48px;
    position: relative;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffdf65), to(white));
    background: linear-gradient(to bottom, #ffdf65 0%, white 100%);
}

.btn-border span {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background: #1C9C41;
    border-radius: inherit;
}

.box-text {
    color: #4F4F4F;
    margin: 0 0 16px;
}

.box-text p {
    margin: 0 0 20px;
}

.box-text p:last-child {
    margin: 0;
}

.btn-accent {
    position: relative;
    color: #4F4F4F;
    font-size: 20px;
    font-weight: bold;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(27, 161, 110, 0.53)), to(rgba(30, 152, 147, 0.76)));
    background: linear-gradient(to bottom, rgba(27, 161, 110, 0.53) 0%, rgba(30, 152, 147, 0.76) 100%);
    text-transform: uppercase;
}

.btn-accent:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("/assets/images/dot-btn.png") repeat center;
    z-index: 1;
    border-radius: inherit;
}

.btn-accent span {
    position: absolute;
    background: -webkit-gradient(linear, left top, left bottom, from(#5fffa8), to(#e7f97b));
    background: linear-gradient(to bottom, #5fffa8 0%, #e7f97b 100%);
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn-back {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(27, 161, 110, 0.53)), to(rgba(30, 152, 147, 0.76)));
    background: linear-gradient(to bottom, rgba(27, 161, 110, 0.53) 0%, rgba(30, 152, 147, 0.76) 100%);
    position: relative;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 1px 2px rgba(0, 0, 0, 0.25);
}

.btn-back:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("/assets/images/dot-btn.png") repeat center;
    z-index: 1;
    border-radius: inherit;
}

.btn-back span {
    position: absolute;
    background: -webkit-gradient(linear, left top, left bottom, from(#5fffa8), to(#e7f97b));
    background: linear-gradient(to bottom, #5fffa8 0%, #e7f97b 100%);
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.button-group-row .btn {
    margin: 0;
    max-width: 140px;
    width: 100%;
}

.about-box {
    width: 100%;
    background: #fff;
    padding: 16px;
    border-radius: 16px;
    margin: 0 0 16px;
    color: #4F4F4F;
}

.about-box:last-child {
    margin: 0;
}

.about-box .box-text {
    color: #9B9B9B;
    font-size: 14px;
}

.about-box .box-text p {
    margin: 0;
}

.about-box h3 {
    margin: 8px 0;
    text-transform: uppercase;
    color: #4F4F4F;
    font-size: 20px;
    font-weight: bold;
}

.about-box__img {
    width: 100%;
    height: 170px;
    background: #C4C4C4;
}

.about-box__title {
    color: #4F4F4F;
    margin: 8px 0;
    width: 100%;
}

.about-box__line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.about-box__line p {
    margin: 0 auto 0 8px;
}

.about-box__number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 32px;
    height: 32px;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 1px solid #F4B70C;
    background: #FFDB1D;
    font-size: 20px;
    font-weight: bold;
}

.list-about-box__line {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 8px;
}

.list-about-box__line p {
    margin: 0 auto 0 8px;
}

.list-about-box li {
    margin: 0 0 8px;
}

.list-about-box li:last-child {
    margin: 0;
}

.list-about-box__num {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: #fff;
    width: 32px;
    height: 32px;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
}

.list-about-box__text {
    font-size: 14px;
}

.color-red {
    color: #FF3D43;
}

.color-green {
    color: #1FD32E;
}

.body-height {
    height: auto;
}

.card-settings {
    background: #fff;
    border-radius: 16px;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 288px;
    width: 100%;
    margin: 0 auto;
    min-height: 488px;
    overflow: hidden;
    color: #4F4F4F;
}

.card-settings .line-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.line-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.line-wrapper .checkbox {
    margin: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.line-wrapper .checkbox-custom {
    margin: 0;
    background: #ffffff;
    border: 2px solid #1C9C41;
}
.line-wrapper .checkbox-inp:checked + .checkbox-custom {
    background: #1C9C41;
}


.variable-letter-disable .card-product-word__box {
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: #EEEEEE;
}

.card-settings__photo {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 278px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-14.14%, #FFDF65), color-stop(123.06%, #FFFFFF));
    background: linear-gradient(180deg, #FFDF65 -14.14%, #FFFFFF 123.06%);
    position: relative;
    overflow: hidden;
}

.card-settings__photo .image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-delete {
    position: absolute;
    top: 8px;
    right: 9px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-settings__body {
    height: calc(100% - 278px);
    width: 100%;
    padding: 14px 16px;
}

.card-settings__body input {
    height: 42px;
}

.card-settings__item {
    margin: 8px 0 0;
}

.card-settings__line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 8px;
}

.card-settings__line p {
    margin: 0 0 0 8px;
}

.list-variable {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.list-variable__item {
    -webkit-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    height: 32px;
    font-size: 20px;
    font-weight: bold;
    width: 32px;
}

.list-variable__item.active {
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: #FFDB1D;
    border: 1px solid #F4B70C;
}

.list-variable-type .list-variable__item {
    width: 75px;
}

.card-settings-two .card-settings__photo {
    background: #fff;
}

.card-settings .card-settings__body label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.line-wrapper .checkbox {
    margin: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.line-wrapper .checkbox-custom {
    margin: 0;
    background: #ffffff;
    border: 2px solid #1C9C41;
}
.line-wrapper .checkbox-inp:checked + .checkbox-custom {
    background: #1C9C41;
}

.card-settings .card-settings__body input {
    width: calc(100% - 34px);
}

.card-settings .card-product-word__box {
    width: 48px;
    height: 48px;
    font-size: 26px;
}

.card-settings .variable-letter {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.icon-view {
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.card-settings__body-line {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-search {
    width: 100%;
    position: relative;
}

.form-search input {
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 48px;
    text-align: left;
    padding: 0 56px 0 16px;
    color: #4F4F4F;
    font-size: 16px;
    font-weight: normal;
}

.form-search button {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: 18px;
    width: 12px;
    height: 12px;
    margin: 0;
    z-index: 1;
    padding: 0;
}

.form-search label {
    margin: 0;
}

.search-result {
    width: 100%;
}
.search-result-content {
    margin: 0 0 8px;
}

.search-result h4 {
    margin: 0 0 6px;
    color: #fff;
    font-size: 14px;
}

.search-result__wrapper {
    width: calc(100% + 40px);
    margin: 0 -20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.search-result__photo {
    width: 25%;
    padding: 0 2px;
    margin: 0 0 3px;
    height: 87px;
    overflow: hidden;
}

.search-result__photo img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.search-result__photo-camera {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #213421;
}
.search-result__photo-camera img {
    max-width: 50%;
    max-height: 50%;
}

.search-result__photo-more {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #213334;
}
.search-result__photo-more img {
    max-width: 20%;
    max-height: 20%;
}


.content-page-search .container {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.btn-scroll-top {
    position: fixed;
    z-index: 5;
    background: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    right: -8px;
    bottom: 20px;
}

.window-search {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 355px;
    padding: 8px 16px 0;
    z-index: 4;
    background: url("/assets/images/bg.png") no-repeat center;
    background-size: cover;
}

.window-search .form-search {
    margin: 0 auto 16px;
}

.btn-line-close {
    margin: 0 auto 8px;
    background: #1FD32E;
    width: 48px;
    height: 8px;
    border-radius: 8px;
}

.btn-delete-card {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.description {
    text-align: center;
    margin: 16px auto;
}

.form-settings {
    margin: 0 0 16px;
}

.form-settings input {
    font-size: 16px;
    font-weight: normal;
    border: 1px solid #EEEEEE;
    -webkit-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.card-line-container {
    width: 100%;
}
.card-line {
    border-radius: 8px;
    height: 100px;
    background: #fff;
    padding: 0 4px;
    margin: 0 0 16px;
    display: flex;
    align-items: stretch;
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    transition: height 0.5s, margin-top 0.5s;
    left: 16px;
    max-width: 448px;
    width: calc(100% - 32px);
}
.card-line > * {
    opacity: 1;
    transition: opacity 0.7s;
}
.card-line.dragging {
    height: 32px;
    margin-top: 34px;
}
.card-line.dragging > * {
    opacity: 0;
    transition: opacity 0.2s;
}

.card-line--card-add {
    height: 100px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    background: #fff;
    padding: 0 4px;
    margin: 0 0 16px;
    display: flex;
    align-items: stretch;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    transition: height 0.5s;
}
.card-line--card-add .card-line__name {
    width: 100%;
}
.card-line--card-add h4 {
    text-align: center;
}

.card-line .btn-view {
    position: absolute;
    top: 9px;
    right: 8px;
    width: 16px;
    height: 14px;
}

.card-line__img {
    height: 100px;
}
.card-line__img img {
    object-fit: contain;
    height: 100%;
}

.card-line-characteristics {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 6px 0;
    margin: 0 8px 0 0;
}

.card-val {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 8px;
    color: #000;
}

.card-val:last-child {
    margin: 0;
}

.card-val p {
    margin: 0 0 0 4px;
}

.card-line__img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 80px;
    overflow: hidden;
}

.card-line__name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.card-line__name h4 {
    width: 100%;
    color: #1FD32E;
    font-size: 14px;
}

.card-line__name-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #4F4F4F;
    text-transform: uppercase;
}

.card-line__name-text-box {
    width: 24px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.card-line-overlay:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
    background: #1FD32E;
    opacity: .25;
    width: 100%;
    height: 100%;
}

.line-border {
    max-width: 293px;
    width: 100%;
    margin: 0 auto 16px;
    height: 4px;
    background: #fff;
}

.bg-card-overlay {
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    -webkit-transform: rotate(4.05deg);
    transform: rotate(4.05deg);
    width: 230px;
    height: 56px;
    background: #8BD1AE;
    opacity: .85;
    color: #1FD32E;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0 27px;
    z-index: 1;
    margin: -60px 0 -40px auto;
}

#copy {
    display: none;
}

@media only screen and (max-width: 375px) {
    .card {
        width: 100px;
    }

    .timer-box span {
        width: 40px;
        height: 40px;
        font-size: 22px;
    }

    .card-product {
        width: 250px;
        height: 370px;
    }

    .card-product:after, .card-product:before {
        height: 354px;
    }

    .header .list-stars li {
        margin: 0 10px 0 0;
    }

    .card-product-img {
        height: 220px;
    }

    .btn-border {
        font-size: 14px;
    }

    .modal-title {
        font-size: 18px;
    }
}

/** fix scroll **/

.wrapper {
    flex-direction: column;
}
.content {
    flex-direction: column;
}
.content .container {
    min-height: initial;
    flex-grow: 1;
}
.container .card-wrapper {
    align-self: center;
}

/** fix svg pixelization **/

.star-yellow-wrapper {
    width: 36px;
    height: 36px;
    position: relative;
}
.star-max-wrapper {
    width: 96px;
    height: 96px;
}

.star-yellow-wrapper img,
.star-max-wrapper img
{
    position: absolute;
    max-width: initial;
    width: 500%;
    height: 500%;
    transform: scale(0.2, 0.2) translate(-200%, -200%);
}


/* disable ios styling inputs and buttons */
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
    -webkit-appearance: none;
}


.btn-youtube-delete {
    background-color: white;
    position: absolute;
    top: 8px;
    right: 9px;
    padding: 10px;
    border-radius: 100%;
}

.video-wrapper {
    position: relative;
}
.video-wrapper .video-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
.video-wrapper video {
    width: 100%;
    height: 200px;
}
.video-wrapper a {
    z-index: 2;
}

/* onboarding */

.onboarding-page .content .container {
    justify-content: center;
}
.onboarding-page .header .container {
    justify-content: space-between;
}
.onboarding-page .header .container .filler,
.onboarding-page .header .container>img {
    height: 100px;
    max-height: 10vh;
}
.onboarding-page .header .container>img {
    transform: scale(0.1, 0.1) !important;
    display: none;
}


.onboarding-page .text-white {
    color: #bcffa0;
    background: -webkit-linear-gradient(#e1f8d7, #c5ffa9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.9;
}

.onboarding-page .text-h4 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}

.onboarding-page .text-h5 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.onboarding-page .overlay>.container {
    height: 100%;
    justify-content: space-around;
    flex-direction: column;

}

.onboarding-page .overlay .onboarding-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.onboarding-page .overlay .illustration-row {
    display: flex;
    flex-direction: row;
}
.onboarding-page .overlay .illustration-row img {
    margin-left: 5px;
    margin-right: 5px;
}

.onboarding-page .overlay {
    background: rgba(0, 0, 0, 0.76);
}

.level-number {
    color: #bcffa0;
    background: -webkit-linear-gradient(#e1f8d7, #c5ffa9);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
    text-transform: uppercase;
}
